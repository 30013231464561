import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from 'components/layout';

import CustomizingEN from '../../articles/en/Customizing';

const CustomizingPage: React.FC = () => (
  <Layout>
    <Helmet>
      <title>Customizing</title>
      <meta name="og:title" content="Customizing" />
      <meta name="twitter:title" content="Customizing" />
    </Helmet>
    <CustomizingEN />
  </Layout>
);

export default CustomizingPage;
