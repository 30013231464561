/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';

import Article, {
  ArticleHeader, ArticleTitle, ArticleContent, ArticleParagraph,
} from 'components/Article';

import check from './icons/check.svg';
import cross from './icons/cross.svg';

import styles from './Customizing.module.scss';

const Customizing: React.FC = () => (
  <Article>
    <ArticleHeader>
      <ArticleTitle level={1}>
        Personnalisation des Albums
      </ArticleTitle>
    </ArticleHeader>
    <ArticleContent>
      <ArticleParagraph>
        Voici une présentation des fonctionnalités offertes pour les albums privés et publics :
      </ArticleParagraph>
      <div className={styles.tableWrapper}>
        <table>
          <thead>
            <tr>
              <td />
              <td>
                <strong>Albums Public</strong>
              </td>
              <td>
                <strong>Albums Privé</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Accès collaborative</td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Accès restreint (sans limitation de temps ou d’ouvertures)</td>
              <td><img src={cross} className={styles.icon} alt="No" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Accès restreint par temps</td>
              <td><img src={cross} className={styles.icon} alt="No" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Accès restreint par nombre d’ouvertures</td>
              <td><img src={cross} className={styles.icon} alt="No" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Ajouter l’option monétisation</td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Ajouter l’option Secret</td>
              <td><img src={cross} className={styles.icon} alt="No" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Ajouter l’option chiffrement de bout en bout</td>
              <td><img src={cross} className={styles.icon} alt="No" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
            <tr>
              <td>Ajouter l’option code d’accès</td>
              <td><img src={cross} className={styles.icon} alt="No" /></td>
              <td><img src={check} className={styles.icon} alt="Yes" /></td>
            </tr>
          </tbody>
        </table>

      </div>
      <ArticleParagraph>
        Présentation des options :
        <ul>
          <li>
            L’option code d’accès : Il est possible pour chaque utilisateur de créer un code d’accès
            dédié à chaque album privé (ce code d’accès n’est pas disponible pour les albums
            publics). L’Utilisateur auteur de l’album communique un code album auprès des
            Utilisateurs de son choix et par le moyen de communication de son choix cela même en
            dehors de l’application Albums. Ces Utilisateurs pourront alors entrer le code album sur
            le profil de l’utilisateur dans l’application Albums. Ce code fera apparaitre l’album
            privé et enverra une invitation d’accès en visionnage seul sans limitation de temps ou
            d’ouverture à l’utilisateur qui a entré le code. Dans le cas où l’utilisateur a
            également ajouté l’option monétisé sur cet album, l’invitation sera transformée en une
            invitation avec le prix et la souscription que l’utilisateur devra accepter. Les codes
            peuvent être pour une utilisation unique ou multiple. Dans le cas d’une utilisation
            multiple, le même code pourra être entré plusieurs fois. Dans le cas de l’utilisation
            unique, le propriétaire de l’album devra créer un code dédié pour chaque utilisateur à
            qui il veut donner accès. Une fois le code entré il sera inutilisable. L’Utilisateur
            auteur de l’album aura toujours le choix de désactiver les codes qu’il a émis.
            <br />
            <br />
            L’option secret : Il est possible d’ajouter un code secret sur tous les albums privés
            (ce code n’est pas disponible sur les albums publics). Ce code permet à l’utilisateur de
            faire disparaitre et faire apparaitre à nouveau l’album de son smartphone (tablettes,
            etc.). Il est possible d’ajouter le même code pour tous les albums privés ou un code
            différent par albums. Attention en cas de perte de code il n’y a pas de récupération de
            code secret.
          </li>
          <li>
            L’option chiffrage de bout en bout (E2E encrypted) : Il est possible d’activer sur les
            albums privés l’option chiffrage de bout en bout. Cette option permet de créer une clé
            de chiffrage unique qui se déchiffre à l’aide d’un code secret unique.
            {' '}
            <strong>
              Attention en cas de perte de code secret il est totalement impossible de récupérer le
              contenue de l’album.
            </strong>
            {' '}
            Cette clé de chiffrage n’est pas stockée sur le cloud et reste uniquement dans le
            smartphone de l’utilisateur.
          </li>
        </ul>
      </ArticleParagraph>
      <ArticleParagraph>
        L’Utilisateur auteur d’un album public, privé, peut permettre à d’autres Utilisateurs
        d’ajouter des photos au sein de ces albums en procédant de la façon suivante : i) en créant
        un album et en lui donnant un nom et éventuellement une photo de couverture, ii) en
        sélectionner le bouton « album public » ou « album privé » en cliquant sur l’icône de
        partage et en sélectionnant les accès collaboratifs. Une fois l’accès sélectionné,
        l’utilisateur pourra désigner un par un les Utilisateurs qui aurons une invitation
        collaborative. Une fois cette invitation acceptée, les différents utilisateurs pourrons
        ajouter des photos et vidéos.
      </ArticleParagraph>
    </ArticleContent>
  </Article>
);

export default Customizing;
